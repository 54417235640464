import '../../assets/stylesheets/custom.scss'
import "bootstrap-icons/font/bootstrap-icons.css";

import * as bootstrap from 'bootstrap'

import "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
if (!window.Rails) Rails.start()
Turbo.start()
Turbo.session.drive = false

function addEventListener(el, eventName, eventHandler, selector) {
  if (selector) {
    const wrappedHandler = (e) => {
      if (e.target && e.target.matches(selector)) {
        eventHandler(e);
      }
    };
    el.addEventListener(eventName, wrappedHandler);
    return wrappedHandler;
  } else {
    el.addEventListener(eventName, eventHandler);
    return eventHandler;
  }
}

addEventListener(document, "DOMContentLoaded", function() {
  let previewWrappers = document.querySelectorAll('.project-list-preview-wrapper')

  const resizeObservers = []
  previewWrappers.forEach(previewWrapper => {
    let preview = previewWrapper.querySelector('.project-list-preview')
    
    const resizeObserver = new ResizeObserver((entries) => {

      let entry = entries[0]

      // These are in pts
      let originalHeight = parseInt(preview.dataset.originalHeight) 
      let originalWidth = parseInt(preview.dataset.originalWidth)

      // These are in pixels
      let newHeight = parseInt(entry.contentRect.height)
      let newWidth = parseInt(entry.contentRect.width)

      // Ratio, but also account for pt to px conversion factor
      let scaleWidth = (newWidth / originalWidth) * (3/4)
      let scaleHeight = (newHeight / originalHeight) * (3/4)

      if(originalHeight < originalWidth) {
        preview.style["transform"] = `scale(${scaleWidth}, ${scaleHeight})`
        preview.style["transform-origin"] = "top left"
      } else {

        preview.style["transform"] = `scale(${scaleHeight}, ${scaleHeight})`
        preview.style["margin"] = "0 auto"
        preview.style["transform-origin"] = "top center"
      }
    })

    resizeObserver.observe(previewWrapper)
    resizeObservers.push(resizeObserver)
  })
})